<template>
  <img :src="imageUrl" :alt="alt" />
</template>

<script setup>
import { ref, watch } from "vue"
import cloudinary from "@/cloudinary"
import defaultGiftImage from "@/assets/default-gift-image.jpeg"

const imageUrl = ref("")

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: undefined,
  },
  width: {
    type: [Number, String],
    default: null,
  },
  height: {
    type: [Number, String],
    default: null,
  },
  quality: {
    type: [Number, String],
    default: "auto:best",
  },
  // Because of hi-res displays, most images will look pixelated. To address this issue, we are loading in a 2x size
  // of the scaled image. This will only be applied when a width and height is provided. So for a 80x80 image, we
  // will load an optimized 160x160 image from Cloudinary.
  retina: {
    type: Boolean,
    default: true,
  },
})

watch(
  () => props.src,
  () => setImageUrl(),
)

const setImageUrl = () => {
  if (props.src.includes("default-gift-image")) {
    imageUrl.value = defaultGiftImage
  } else {
    const transformations = [`q_${props.quality}`, "c_fill"]

    if (props.width) {
      transformations.push(`w_${props.width * (props.retina ? 2 : 1)}`)
    }

    if (props.height) {
      transformations.push(`h_${props.height * (props.retina ? 2 : 1)}`)
    }

    imageUrl.value = cloudinary
      .image(props.src)
      .setDeliveryType("fetch")
      .addTransformation(transformations.join(","))
      .toURL()
  }
}

setImageUrl()
</script>
